.head-position {
  display: flex;
  justify-content: space-evenly;
}

.person {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.person h3,
.person p {
  margin: 0;
}

.validation {
  width: 10%;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column-position {
  margin: 20px 0;
  width: 35%;
  background-color: white;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-position h2 {
  margin: 0;
}

.column-position p {
  margin: 0;
}

.nouveau {
  background-color: #f8b332;
  color: white;
  font-family: 'Nexa-Light';
}

.position-nouveau .case {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.case-empty {
  width: 35%;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  transition: background ease 0.2s;
}

.case-empty h2 {
  margin-left: 20px;
}

.case-empty::before {
  content: '';
  background: url('../assets/arriere-raye.png') center;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.5;
  box-sizing: content-box;
}

.case-empty:hover {
  background: #abb9b366;
}

.valider-emploi {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: flex;
  align-items: center;
  height: 20%;
  justify-content: center;
  padding: 0.5rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096; /* #5850eb; */
  text-decoration: none;
}

.recommencer-emploi {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: flex;
  align-items: center;
  height: 20%;
  justify-content: center;
  padding: 0.5rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #252f3f;
  background-color: white;
  text-decoration: none;
  cursor: pointer;
}
