.vos-documents {
  padding: 3rem;
  padding-top: 1.5rem;
}

.vos-documents dd {
  text-align: right;
}

.vos-documents dt {
  font-family: 'Nexa-Bold';
}

.download {
  cursor: pointer;
  outline: none;
  font-family: 'Nexa-Light';
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
  margin-left: 2rem;
}

.download:hover {
  background-color: #177a74;
}

.details {
  font-family: 'Nexa-Light';
  font-size: 0.9em;
}

.generaux {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.generaux section {
  width: 47%;
  margin-bottom: 2em;
}

.generaux .element {
  grid-template-columns: 3fr 1fr;
}

.nom-entreprise {
  align-self: center;
}

iframe {
  width: 100vw;
  height: 100vh;
}
