.pack {
  padding: 1rem;
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding-bottom: 0;
  width: 70%;
  align-self: center;
  margin-top: 2rem;
}

.modal .top h2 {
  margin-right: 2em !important;
  margin-left: 2em !important;
}

.pack h2 {
  margin: 0;
}

.pack > p {
  margin-bottom: 0;
  font-size: 0.9em;
  color: gray;
}

.pack-check {
  display: flex;
  flex-direction: column;
}

.pack-check ul {
  margin: 0;
  font-size: 0.9em;
}

.pack-check li {
  font-family: 'Nexa-Bold';
}

#video-demo {
  margin-top: 3rem;
}

.buttons-je-decouvre {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.check {
  display: flex;
  align-items: center;
}

.check img {
  margin-right: 5px;
}

.check div {
  display: flex;
  flex-direction: column;
  color: gray;
}

.border-check p {
  margin: 0;
}

.border-check {
  margin-bottom: 1em;
}
