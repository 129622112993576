.head-assistant .position-actuel {
  width: 35%;
}

.head-assistant .position-nouveau {
  width: 35%;
}

.head-assistant .position-nouveau .case {
  box-shadow: 2px 2px 5px 0px #252f3f;
}

.head-assistant .case {
  border-radius: 0 0 0.25rem 0.25rem;
  width: auto;
}

.head-assistant .column-position {
  margin: 1rem 0 0 0;
  border-radius: 0.25rem 0.25rem 0 0;
  width: auto;
}

.head-assistant .description-header {
  color: black;
  margin: 5rem;
}

.div-calcul {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
