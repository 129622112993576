.img-home {
  width: 70%;
  height: 100vh;
  background: url(../assets/champs.jpg) center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  -webkit-clip-path: polygon(26% 0%, 100% 0%, 100% 100%, 5% 100%);
  clip-path: polygon(26% 0%, 100% 0%, 100% 100%, 5% 100%);
}
.main-home {
  position: relative;
  display: flex;
}

.content {
  position: relative;
  width: 50%;
  padding: 0 0 0 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.top {
  display: flex;
  flex-direction: column;
}

.react-toast-notifications__toast {
  width: 50vw !important;
}

.react-toast-notifications__toast * {
  font-size: 1.2em !important;
}