.buttons-calcul {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 1rem 0 0 0;
}

#valider {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #f8b332;
  text-decoration: none;
  font-family: 'Nexa';
  font-size: 1.1em;
  outline: none;
  cursor: pointer;
}

#valider:active {
  background-color: #cc9329;
}

.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  opacity: 0.2 !important;
}

.calcul {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

#calculer {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 4rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
  text-decoration: none;
  font-family: 'Nexa';
  font-size: 1.1em;
  outline: none;
  cursor: pointer;
}

#calculer:active {
  background-color: #127e77;
}

#simuler {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
  text-decoration: none;
  font-family: 'Nexa';
  font-size: 1.1em;
  outline: none;
  cursor: pointer;
}

#simuler:active {
  background-color: #127e77;
}
