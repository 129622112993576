.ajout-salarie {
  width: 47%;
  height: 10rem;
  position: relative;
  margin-bottom: 2em;
}

.filtre:before {
  content: '';
  background: url('../assets/arriere-raye.png') center;
  background-size: contain;
  width: 100%;
  height: 10rem;
  position: absolute;
  opacity: 0.4;
}

.filtre {
  width: 100%;
  height: 10rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  transition: background ease 0.2s;
}

.filtre:hover {
  background: #abb9b366;
}

.ajout-salarie h2 {
  margin-left: 20px;
  color: black;
}
