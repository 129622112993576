form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: white;
  border-radius: 0.25rem;
}

form label {
  box-sizing: border-box;
  display: grid;
  place-content: center;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  padding: 0.5em 2em;
  color: #6b7280;
  font-size: 1.1em;
}

form label:nth-child(odd) {
  background-color: #f7f7f7;
}

.title-form {
  margin-top: 1em;
  padding: 1rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: white;
  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom: 0;
  border-bottom: solid #dcdcdccd 0.1px;
  font-size: 1.2em;
  display: flex;
  justify-content: space-between;
}

.title-form h3 {
  margin: 0;
  width: 100%;
  text-align: center;
  font-family: 'Nexa-Bold';
}

form input {
  padding: 0.2em;
  font-family: 'Nexa-Bold';
  font-size: 1em;
}

form input[type='submit'] {
  margin: 1rem;
  cursor: pointer;
  outline: none;
  font-family: 'Nexa-Light';
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
}

form input[type='submit']:hover {
  background-color: #10746d;
}

.main-entreprise .modal {
  width: 40%;
}
