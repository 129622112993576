.login-page {
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  height: 100vh;
}

.login-page input,
.login-page label {
  background-color: white;
  font-family: 'Nexa-Light';
}

.login-page form {
  box-shadow: none;
}

.input-login fieldset {
  border-color: #18a096 !important;
}

.input-login label:focus {
  color: black;
}

.MuiFormLabel-root.Mui-focused {
  color: black !important;
}

.login-page form > div {
  width: 100%;
  margin-top: 1em;
}

.div-formulaire {
  width: 20em;
  background-color: white;
  padding: 2em;
  border-radius: 8px;
  box-shadow: 0 19px 38px rgba(83, 83, 83, 0.3),
    0 15px 12px rgba(83, 83, 83, 0.22);
}

.login-page form {
  background-color: white;
}

.login-page h1 {
  margin-top: 0;
  text-align: center;
  font-family: 'Nexa-bold';
}

.login-page h4 {
  text-align: center;
  margin-bottom: 2em;
  font-family: 'Nexa-Light';
}

.login-page button {
  margin-top: 2em;
  font-family: 'Nexa-bold';
  background-color: #18a096;
  padding: 0.7em 2em;
  text-transform: capitalize;
}

.login-page button:hover {
  background-color: #14867f;
}

.login-page form .login-page form > div:nth-child(1) {
  margin-top: 0;
}

.login-page button span {
  font-size: 1.2em !important;
}

.login-page form label:nth-child(odd) {
  background: none;
}

.MuiOutlinedInput-inputMarginDense {
  font-family: 'Nexa-Light' !important;
}
.login-page iframe {
  width: 100%;
  height: auto;
}
