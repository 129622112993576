.select-input {
  width: 10rem;
}

.simulateur-cout-global {
  font-size: 1.1em;
}

.simulateur-cout-global label {
  display: flex;
  align-items: center;
}

#temps-partiel {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2px 8px;
  width: 5rem;
  font-family: 'Nexa-Light';
  font-size: 1.03em;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

#temps-partiel:focus {
  border: 1px solid #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}

.show-position {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.block-head {
  display: flex;
  flex-direction: column;
}

.block-head h2 {
  font-size: 1.1em;
}

.show-position .resultat-glob {
  width: 49% !important;
}

.resultat-glob {
  display: flex;
  flex-direction: column;
}

.infos-simulation {
  margin-top: 4em;
  font-size: 0.85em;
}
