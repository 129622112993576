.buttons {
  width: 30rem;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}

.buttons button {
  font-size: 1.1em;
}
