.date-pick {
  padding: 0.5em;
  border-radius: 5px;
  font-family: 'Nexa-Light';
}

.window-backoffice {
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 90vh;
}

.MuiAppBar-colorPrimary {
  background-color: #4b4b4d !important;
}

.input-code {
  font-family: 'Nexa-Light' !important;
  margin-bottom: 1em !important;
}

.input-code fieldset {
  border-color: #000000 !important;
}

.input-code label:focus {
  color: black;
}

.choix-date {
  background-color: white;
  padding: 1em;
  width: auto;
  width: 20em;
  display: flex;
  z-index: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-code * {
  font-family: 'Nexa-bold' !important;
}

.date-pick * {
  font-family: 'Nexa-bold' !important;
}

.choix-date p {
  font-family: 'Nexa-Light' !important;
}

.field-date {
  align-items: center;
  display: flex;
}

.field-date p {
  margin-right: 1em;
}

.MuiAppBar-positionFixed {
  position: relative !important;
}

.MuiTab-textColorPrimary * {
  font-family: 'Nexa-Light';
}

.MuiTab-textColorPrimary.Mui-selected * {
  color: black !important;
  font-family: 'Nexa-Bold' !important;
}

.MuiTab-textColorPrimary {
  width: 50% !important;
  max-width: 100% !important;
  color: rgba(255, 255, 255, 0.54);
}

.makeStyles-root-5 {
  margin-bottom: 3em;
  border-color: #18a096 !important;
}

.choix-date #export-csv-entreprise {
  margin-top: 3em;
  padding: 0.5em 1em;
  width: 18em;
  font-family: none !important;
  font-family: 'Nexa-bold' !important;
  font-size: 0.8em;
  background-color: #3f51b5 !important;
  color: white !important;
}

.choix-date #export-csv-salaries {
  margin-top: 0.9em;
  padding: 0.5em 1em;
  width: 18em;
  font-family: none !important;
  font-family: 'Nexa-bold' !important;
  font-size: 0.8em;
  background-color: #18a096 !important;
  color: white !important;
}

.choix-date #export-csv-calculs {
  margin-top: 0.9em;
  padding: 0.5em 1em;
  width: 18em;
  font-family: none !important;
  font-family: 'Nexa-bold' !important;
  font-size: 0.8em;
  background-color: #4b4b4d !important;
  color: white !important;
}

.top-bar-backoffice {
  justify-content: space-between !important;
  font-family: 'Nexa-Light' !important;
}

.top-bar-backoffice * {
  font-family: 'Nexa-Light' !important;
}

.disconnect-top-bar * {
  font-family: 'Nexa-bold' !important;
}

.jss8 {
  background-color: #000000 !important;
}

.MuiTabs-indicator {
  background-color: #000000 !important;
}