.bold {
  font-family: 'Nexa-Bold';
}

#title {
  font-size: 2.15em;
  margin: 0;
}

#sub-title {
  font-size: 1.3em;
}

.description p {
  text-align: justify;
  font-size: 0.9em;
}

.button-video {
  position: relative;
  margin-bottom: 0.8em;
  align-self: flex-end;
  right: 0;
  font-family: 'Nexa-Bold';
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #32ba7c;
  background-color: white;
  text-decoration: none;
}
