.column {
  margin: 0;
  width: 13em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-name {
  height: 2.5rem;
  text-align: center;
  font-size: 1.3em;
}

.column-name p {
  margin: 0;
}

.info-popup {
  margin-left: 0.7em;
  cursor: pointer;
}

.calcul .case {
  border-radius: 0 0 0.25rem 0.25rem;
  width: 100%;
}

.description-case {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  height: 9rem !important;
  margin-top: 4rem;
  text-align: center;
  font-size: 0.9em;
}

.calcul {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
}

datalist {
  display: flex;
  justify-content: space-between;
  width: 15rem;
  height: 8rem;
  top: 4.5rem;
  position: absolute;
  transform-origin: center;
  transform: rotate(-90deg);
  z-index: 1 !important;
  pointer-events: none;
}

datalist option {
  background: white;
  min-height: 13px;
  height: 10px;
  z-index: 0 !important;
}

.div-input {
  position: relative;
}

datalist option:first-of-type {
  visibility: hidden;
}

datalist option:last-of-type {
  visibility: hidden;
}

.sub-column-name {
  font-size: 0.7em;
  font-family: 'Nexa-Light';
}
/** for chrome **/
input[type='range'] {
  margin: 0;
  margin-top: 4.5rem !important;
  width: 15rem;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
  transform-origin: center;
  transform: rotate(-90deg);
  position: relative;
  box-sizing: border-box;
  height: 8rem;
}

input[type='range']::-webkit-slider-thumb {
  color: rgb(24, 160, 150);
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  z-index: 5 !important;
  height: 8rem;
  background: #127e77;
  cursor: pointer;
}

/** FF **/
input[type='range']::-moz-range-thumb {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  z-index: 5 !important;
  height: 8rem;
  background: #127e77;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 0;
}

input[type='range']::-moz-range-progress {
  background-color: #18a096;
}
input[type='range']::-moz-range-track {
  background-color: #888888;
}

/** IE **/
input[type='range']::-ms-fill-lower {
  background-color: #18a096;
}
input[type='range']::-ms-fill-upper {
  background-color: #888888;
}

.info-column-popup {
  display: flex;
  flex-direction: column;
}

.info-column-popup img {
  align-self: center;
}

.img-modal {
  width: 100% !important;
}

.download-position {
  align-self: center;
  cursor: pointer;
  outline: none;
  font-family: 'Nexa-Light';
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 20em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
  font-size: 1.1em;
  margin-top: 1rem;
}

.download-position:hover {
  background-color: #10746d;
}

.valeur-column {
  position: absolute;
  z-index: 1;
  left: 3.5rem;
  top: 1.7em;
  color: white;
  width: 8rem;
  font-size: 2.5em;
  text-align: center;
  font-family: 'Nexa-Bold';
  pointer-events: none;
}
