.case {
  width: 35%;
  height: 5rem;
  background-color: white;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.25rem;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.case p {
  margin: 0;
}

.bottom-case {
  justify-content: space-between;
  display: flex;
}

.element-case {
  display: flex;
  padding: 0.125rem 0;
  align-items: center;
}

.element-name {
  width: 7.5rem;
}

.alert-salaire {
  font-size: 0.8em;
  position: absolute;
  bottom: 0.125rem;
  right: 1rem;
  color: #e8501f;
}

.info-popup-all {
  margin-left: 1em;
  cursor: pointer;
}
