.top-bar {
  height: 1rem;
  display: flex;
  align-items: center;
  color: white;
  background-color: #888888;
  padding: 0.75rem 0;
}

.top-bar h2 {
  margin: 0;
  padding: 0.75rem;
  width: 30%;
  text-align: left;
}

.top-bar div {
  padding: 0.75rem;
  padding-left: 10rem;
  height: 100%;
  width: 70%;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 1.5em;
  clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 5% 100%);
  background-color: #4b4b4d;
}

.top-bar p {
  margin: 0;
}

.top-bar-emploi {
  margin-left: 10px !important;
}
