#emploi-input {
  padding: 0.2em;
  font-family: 'Nexa-Light';
  font-size: 1.1em;
  width: 16em;
}

.ok-pour-moi {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
  text-decoration: none;
  font-family: 'Nexa';
  font-size: 1.1em;
  outline: none;
  cursor: pointer;
}

.ok-pour-moi:active {
  background-color: #127e77;
}

.bar-button {
  margin-top: 2em;
  width: 100%;
  display: flex;
  place-content: center;
}
