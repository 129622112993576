.start {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #32ba7c;
  text-decoration: none;
}
.start:hover {
  color: red;
  background-color: #0aa06e;
}

.discover {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #32ba7c;
  background-color: white;
  text-decoration: none;
}

.discover:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
