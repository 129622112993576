.telecharger-tout-glob {
  cursor: pointer;
  outline: none;
  font-family: 'Nexa-Light';
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 25em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
  font-size: 1.1em;
}

.telecharger-tout-glob:hover {
  background-color: #10746d;
}

.gestionnaire-glob {
  cursor: pointer;
  outline: none;
  font-family: 'Nexa-Light';
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 25em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #e8501f;
  margin-left: 2rem;
  font-size: 1.1em;
}

.gestionnaire-glob:hover {
  background-color: #bb4118;
}

.boutons-glob {
  display: flex;
  justify-content: center;
}
