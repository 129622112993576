a[href]:not([class]) {
  color: #32ba7c;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}

.wrapper {
  width: 600px;
  height: 100%;
  margin: auto;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.5s;
  z-index: 2;
}

.modal {
  width: 60%;
  position: relative;
  margin: auto;
  top: 3em;
  margin-bottom: 3em;
  padding: 1.5rem;
  background-color: #f7f7f7;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  z-index: 3 !important;
}
.modal .content-modal {
  margin-top: 1rem;
  font-size: 0.9em;
}

.modal-je-decouvre {
  display: block;
  overflow-y: scroll !important;
  height: 80vh;
}

.content-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.overlay:target > .modal {
  transform: translateY(30%) scale(0.9);
  transition-timing-function: cubic-bezier(0.8, 0, 0, 1.5);
  opacity: 1;
}

.overlay:target > .modal-je-decouvre {
  transform: translateY(0%) scale(0.9);
}

.overlay:target > .modal-je-continue {
  transform: translateY(0%) scale(0.9);
}

.modal h2 {
  text-align: center;
  margin-bottom: 2em;
}

.pack h2 {
  margin: 1rem;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 22px;
  height: 22px;
  text-decoration: none;
  display: grid;
  place-content: center;
  font-size: 20px;
  line-height: 21px;
  background-color: lightgrey;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  transition: 0.25s;
}
.close-button:hover {
  background-color: tomato;
  color: white;
}

.buttons-popup {
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.buttons-popup button {
  font-size: 1.1em;
  margin-left: 1em;
}

button {
  cursor: pointer;
}

.button {
  margin-left: 1rem;
}

#continue {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #32ba7c;
  text-decoration: none;
}

#continue:hover {
  background-color: #0aa06e;
}

#cancel {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #32ba7c;
  font-family: 'Nexa-Light';
  background-color: white;
  text-decoration: none;
}

#cancel:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.retour {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #32ba7c;
  background-color: white;
  text-decoration: none;
}

.retour:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  opacity: 0.2;
}
