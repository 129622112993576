#myChart {
  width: 65vw !important;
  height: auto !important;
  margin-top: 3em;
  max-height: 80vh;
}

.PrivateTabIndicator-colorSecondary-8 {
  background-color: black !important;
}

.MuiTabs-indicator {
  left: 0 !important;
  width: 100% !important;
  right: 0 !important;
}

.makeStyles-formControl-7 {
  width: 70% !important;
}

.choix-date .MuiInputLabel-outlined {
  background-color: white !important;
}

.choix-date .MuiFormControl-root {
  width: 70% !important;
}
