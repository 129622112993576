.salaries {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.salaries > section {
  border-radius: 0.25rem;
  width: 47%;
  margin-bottom: 2rem;
}

main {
  min-height: 80vh;
}
