.emploi {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
}

.position-nouveau .case {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.case-empty {
  width: 35%;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  transition: background ease 0.2s;
}

.case-empty h2 {
  margin-left: 20px;
}

.case-empty::before {
  content: '';
  background: url('../assets/arriere-raye.png') center;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.5;
  box-sizing: content-box;
}

.case-empty:hover {
  background: #abb9b366;
}

.recommencer-emploi {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: flex;
  align-items: center;
  height: 20%;
  justify-content: center;
  padding: 0.5rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #252f3f;
  background-color: white;
  text-decoration: none;
  cursor: pointer;
  padding: 1em 0;
  font-family: 'Nexa-Light';
  font-size: 1em;
}

.recommencer-emploi:hover {
  background-color: rgb(241, 241, 241);
}
