:root {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

@font-face {
  font-family: 'Nexa-bold';
  src: url(font/nexa/Nexa_Bold.otf);
}

@font-face {
  font-family: 'Nexa-Light';
  src: url(font/nexa/Nexa_Light.otf);
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body {
  margin: 0;
  font-family: 'Nexa-Light', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-aide {
  margin-left: 3em;
  font-size: 1.25em;
  all: unset;
  background-color: white;
  cursor: pointer;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
}

.button-aide:hover {
  background-color: rgb(224, 223, 223);
}
