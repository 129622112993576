.etape {
  background-color: #4b4b4d;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  position: sticky;
  bottom: 0;
  font-size: 1.05em;
}

.etape button {
  font-size: 1em;
}
.etape p {
  margin: 0 0.5em;
  font-size: 0.9em;
}

.etape h2 {
  margin: 0;
  text-align: center;
  font-size: 1.2em;
}

.etape-validate {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: flex;
  align-items: center;
  height: 80%;
  justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096; /* #5850eb; */
  text-decoration: none;
  font-family: 'Nexa-Bold';
}

.etape-validate:hover {
  background-color: #177a74;
}

.etape-retour {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: flex;
  align-items: center;
  height: 80%;
  justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #18a096;
  background-color: white;
  text-decoration: none;
  font-family: 'Nexa-Bold';
}

.etape-retour:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
