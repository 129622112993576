.alert-popup {
  font-size: 1.2em;
}

.alert-popup h2 {
  color: red;
}

.alert-popup button {
  width: auto !important;
}

.alert-popup .buttons-popup {
  justify-content: center;
}
