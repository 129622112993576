header {
  position: relative;
}

.dark {
  background-color: #f8b332;
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dark h1 {
  margin: 0;
  color: white;
  text-align: left;
}

.description-header {
  font-size: 1.2em;
  color: white;
}

.dark h2 {
  color: white;
  font-size: 1.5em;
}

.dark section {
  width: 50%;
}

.dark > div {
  width: auto;
  margin-right: 10px;
}

.title-global {
  font-size: 4em;
  text-align: center;
}

.main-entreprise {
  margin: 0 3rem;
}

.infos-entreprise {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: white;
  border-radius: 0.25rem;
}

.infos-head {
  padding: 1rem;
  border-bottom: solid #dcdcdccd 0.1px;
  display: flex;
  justify-content: space-between;
}

i {
  cursor: pointer;
}

.infos-head h3 {
  margin: 0;
  font-family: 'Nexa-Bold';
}

dl {
  margin: 0;
}

dd {
  font-family: 'Nexa-Bold';
  margin: 1px;
}

dt {
  color: #6b7280;
}

.element {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  padding: 0.5rem;
}

.element:nth-child(odd) {
  background-color: #f7f7f7;
}
