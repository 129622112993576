@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
:root {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

@font-face {
  font-family: 'Nexa-bold';
  src: url(/static/media/Nexa_Bold.c2b99812.otf);
}

@font-face {
  font-family: 'Nexa-Light';
  src: url(/static/media/Nexa_Light.2bac835e.otf);
}

body {
  margin: 0;
  font-family: 'Nexa-Light', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-aide {
  margin-left: 3em;
  font-size: 1.25em;
  all: unset;
  background-color: white;
  cursor: pointer;
  box-shadow: 0 0 #0000,
    0 0 #0000, 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
}

.button-aide:hover {
  background-color: rgb(224, 223, 223);
}

a[href]:not([class]) {
  color: #32ba7c;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}

.wrapper {
  width: 600px;
  height: 100%;
  margin: auto;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.5s;
  z-index: 2;
}

.modal {
  width: 60%;
  position: relative;
  margin: auto;
  top: 3em;
  margin-bottom: 3em;
  padding: 1.5rem;
  background-color: #f7f7f7;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  transition: 0.5s;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  z-index: 3 !important;
}
.modal .content-modal {
  margin-top: 1rem;
  font-size: 0.9em;
}

.modal-je-decouvre {
  display: block;
  overflow-y: scroll !important;
  height: 80vh;
}

.content-modal {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.overlay:target > .modal {
  -webkit-transform: translateY(30%) scale(0.9);
          transform: translateY(30%) scale(0.9);
  transition-timing-function: cubic-bezier(0.8, 0, 0, 1.5);
  opacity: 1;
}

.overlay:target > .modal-je-decouvre {
  -webkit-transform: translateY(0%) scale(0.9);
          transform: translateY(0%) scale(0.9);
}

.overlay:target > .modal-je-continue {
  -webkit-transform: translateY(0%) scale(0.9);
          transform: translateY(0%) scale(0.9);
}

.modal h2 {
  text-align: center;
  margin-bottom: 2em;
}

.pack h2 {
  margin: 1rem;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 22px;
  height: 22px;
  text-decoration: none;
  display: grid;
  place-content: center;
  font-size: 20px;
  line-height: 21px;
  background-color: lightgrey;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  transition: 0.25s;
}
.close-button:hover {
  background-color: tomato;
  color: white;
}

.buttons-popup {
  margin-top: 2rem;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.buttons-popup button {
  font-size: 1.1em;
  margin-left: 1em;
}

button {
  cursor: pointer;
}

.button {
  margin-left: 1rem;
}

#continue {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 10rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #32ba7c;
  text-decoration: none;
}

#continue:hover {
  background-color: #0aa06e;
}

#cancel {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 10rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #32ba7c;
  font-family: 'Nexa-Light';
  background-color: white;
  text-decoration: none;
}

#cancel:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.retour {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 10rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #32ba7c;
  background-color: white;
  text-decoration: none;
}

.retour:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  opacity: 0.2;
}

.start {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 13rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #32ba7c;
  text-decoration: none;
}
.start:hover {
  color: red;
  background-color: #0aa06e;
}

.discover {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 13rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #32ba7c;
  background-color: white;
  text-decoration: none;
}

.discover:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.buttons {
  width: 30rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: 0 auto;
}

.buttons button {
  font-size: 1.1em;
}

.bold {
  font-family: 'Nexa-Bold';
}

#title {
  font-size: 2.15em;
  margin: 0;
}

#sub-title {
  font-size: 1.3em;
}

.description p {
  text-align: justify;
  font-size: 0.9em;
}

.button-video {
  position: relative;
  margin-bottom: 0.8em;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  right: 0;
  font-family: 'Nexa-Bold';
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem 1rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #32ba7c;
  background-color: white;
  text-decoration: none;
}

.pack {
  padding: 1rem;
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding-bottom: 0;
  width: 70%;
  -webkit-align-self: center;
          align-self: center;
  margin-top: 2rem;
}

.modal .top h2 {
  margin-right: 2em !important;
  margin-left: 2em !important;
}

.pack h2 {
  margin: 0;
}

.pack > p {
  margin-bottom: 0;
  font-size: 0.9em;
  color: gray;
}

.pack-check {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.pack-check ul {
  margin: 0;
  font-size: 0.9em;
}

.pack-check li {
  font-family: 'Nexa-Bold';
}

#video-demo {
  margin-top: 3rem;
}

.buttons-je-decouvre {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.check {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.check img {
  margin-right: 5px;
}

.check div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: gray;
}

.border-check p {
  margin: 0;
}

.border-check {
  margin-bottom: 1em;
}

.img-home {
  width: 70%;
  height: 100vh;
  background: url(/static/media/champs.c9c51b93.jpg) center;
  background-size: cover;
  position: relative;
  -webkit-clip-path: polygon(26% 0%, 100% 0%, 100% 100%, 5% 100%);
  clip-path: polygon(26% 0%, 100% 0%, 100% 100%, 5% 100%);
}
.main-home {
  position: relative;
  display: -webkit-flex;
  display: flex;
}

.content {
  position: relative;
  width: 50%;
  padding: 0 0 0 5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.top {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.react-toast-notifications__toast {
  width: 50vw !important;
}

.react-toast-notifications__toast * {
  font-size: 1.2em !important;
}
.div-redirection {
  margin: 15em;
}

@media only screen and (max-width: 1000px) {
  .div-redirection {
    margin: 15em 1em;
  }
}

.etape {
  background-color: #4b4b4d;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.5rem 1rem;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  font-size: 1.05em;
}

.etape button {
  font-size: 1em;
}
.etape p {
  margin: 0 0.5em;
  font-size: 0.9em;
}

.etape h2 {
  margin: 0;
  text-align: center;
  font-size: 1.2em;
}

.etape-validate {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 80%;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096; /* #5850eb; */
  text-decoration: none;
  font-family: 'Nexa-Bold';
}

.etape-validate:hover {
  background-color: #177a74;
}

.etape-retour {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 80%;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.75rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #18a096;
  background-color: white;
  text-decoration: none;
  font-family: 'Nexa-Bold';
}

.etape-retour:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.vos-documents {
  padding: 3rem;
  padding-top: 1.5rem;
}

.vos-documents dd {
  text-align: right;
}

.vos-documents dt {
  font-family: 'Nexa-Bold';
}

.download {
  cursor: pointer;
  outline: none;
  font-family: 'Nexa-Light';
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 10rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
  margin-left: 2rem;
}

.download:hover {
  background-color: #177a74;
}

.details {
  font-family: 'Nexa-Light';
  font-size: 0.9em;
}

.generaux {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.generaux section {
  width: 47%;
  margin-bottom: 2em;
}

.generaux .element {
  grid-template-columns: 3fr 1fr;
}

.nom-entreprise {
  -webkit-align-self: center;
          align-self: center;
}

iframe {
  width: 100vw;
  height: 100vh;
}

.telecharger-tout-glob {
  cursor: pointer;
  outline: none;
  font-family: 'Nexa-Light';
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 25em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
  font-size: 1.1em;
}

.telecharger-tout-glob:hover {
  background-color: #10746d;
}

.gestionnaire-glob {
  cursor: pointer;
  outline: none;
  font-family: 'Nexa-Light';
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 25em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #e8501f;
  margin-left: 2rem;
  font-size: 1.1em;
}

.gestionnaire-glob:hover {
  background-color: #bb4118;
}

.boutons-glob {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

header {
  position: relative;
}

.dark {
  background-color: #f8b332;
  padding: 1rem 3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.dark h1 {
  margin: 0;
  color: white;
  text-align: left;
}

.description-header {
  font-size: 1.2em;
  color: white;
}

.dark h2 {
  color: white;
  font-size: 1.5em;
}

.dark section {
  width: 50%;
}

.dark > div {
  width: auto;
  margin-right: 10px;
}

.title-global {
  font-size: 4em;
  text-align: center;
}

.main-entreprise {
  margin: 0 3rem;
}

.infos-entreprise {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: white;
  border-radius: 0.25rem;
}

.infos-head {
  padding: 1rem;
  border-bottom: solid #dcdcdccd 0.1px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

i {
  cursor: pointer;
}

.infos-head h3 {
  margin: 0;
  font-family: 'Nexa-Bold';
}

dl {
  margin: 0;
}

dd {
  font-family: 'Nexa-Bold';
  margin: 1px;
}

dt {
  color: #6b7280;
}

.element {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0.5rem;
}

.element:nth-child(odd) {
  background-color: #f7f7f7;
}

.head-position {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.person {
  width: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.person h3,
.person p {
  margin: 0;
}

.validation {
  width: 10%;
  height: 5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.column-position {
  margin: 20px 0;
  width: 35%;
  background-color: white;
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.75rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.column-position h2 {
  margin: 0;
}

.column-position p {
  margin: 0;
}

.nouveau {
  background-color: #f8b332;
  color: white;
  font-family: 'Nexa-Light';
}

.position-nouveau .case {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.case-empty {
  width: 35%;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  background: none;
  transition: background ease 0.2s;
}

.case-empty h2 {
  margin-left: 20px;
}

.case-empty::before {
  content: '';
  background: url(/static/media/arriere-raye.e94d533c.png) center;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.5;
  box-sizing: content-box;
}

.case-empty:hover {
  background: #abb9b366;
}

.valider-emploi {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 20%;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096; /* #5850eb; */
  text-decoration: none;
}

.recommencer-emploi {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 20%;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #252f3f;
  background-color: white;
  text-decoration: none;
  cursor: pointer;
}

.case {
  width: 35%;
  height: 5rem;
  background-color: white;
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.25rem;
  padding: 0.75rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
}

.case p {
  margin: 0;
}

.bottom-case {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  display: -webkit-flex;
  display: flex;
}

.element-case {
  display: -webkit-flex;
  display: flex;
  padding: 0.125rem 0;
  -webkit-align-items: center;
          align-items: center;
}

.element-name {
  width: 7.5rem;
}

.alert-salaire {
  font-size: 0.8em;
  position: absolute;
  bottom: 0.125rem;
  right: 1rem;
  color: #e8501f;
}

.info-popup-all {
  margin-left: 1em;
  cursor: pointer;
}

.emploi {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 30px;
}

.position-nouveau .case {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.case-empty {
  width: 35%;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  background: none;
  transition: background ease 0.2s;
}

.case-empty h2 {
  margin-left: 20px;
}

.case-empty::before {
  content: '';
  background: url(/static/media/arriere-raye.e94d533c.png) center;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.5;
  box-sizing: content-box;
}

.case-empty:hover {
  background: #abb9b366;
}

.recommencer-emploi {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 20%;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: #252f3f;
  background-color: white;
  text-decoration: none;
  cursor: pointer;
  padding: 1em 0;
  font-family: 'Nexa-Light';
  font-size: 1em;
}

.recommencer-emploi:hover {
  background-color: rgb(241, 241, 241);
}

.salaries {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.salaries > section {
  border-radius: 0.25rem;
  width: 47%;
  margin-bottom: 2rem;
}

main {
  min-height: 80vh;
}

.ajout-salarie {
  width: 47%;
  height: 10rem;
  position: relative;
  margin-bottom: 2em;
}

.filtre:before {
  content: '';
  background: url(/static/media/arriere-raye.e94d533c.png) center;
  background-size: contain;
  width: 100%;
  height: 10rem;
  position: absolute;
  opacity: 0.4;
}

.filtre {
  width: 100%;
  height: 10rem;
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.25rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  background: none;
  transition: background ease 0.2s;
}

.filtre:hover {
  background: #abb9b366;
}

.ajout-salarie h2 {
  margin-left: 20px;
  color: black;
}

form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: white;
  border-radius: 0.25rem;
}

form label {
  box-sizing: border-box;
  display: grid;
  place-content: center;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  padding: 0.5em 2em;
  color: #6b7280;
  font-size: 1.1em;
}

form label:nth-child(odd) {
  background-color: #f7f7f7;
}

.title-form {
  margin-top: 1em;
  padding: 1rem;
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: white;
  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom: 0;
  border-bottom: solid #dcdcdccd 0.1px;
  font-size: 1.2em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.title-form h3 {
  margin: 0;
  width: 100%;
  text-align: center;
  font-family: 'Nexa-Bold';
}

form input {
  padding: 0.2em;
  font-family: 'Nexa-Bold';
  font-size: 1em;
}

form input[type='submit'] {
  margin: 1rem;
  cursor: pointer;
  outline: none;
  font-family: 'Nexa-Light';
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 12rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
}

form input[type='submit']:hover {
  background-color: #10746d;
}

.main-entreprise .modal {
  width: 40%;
}

.column {
  margin: 0;
  width: 13em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.column-name {
  height: 2.5rem;
  text-align: center;
  font-size: 1.3em;
}

.column-name p {
  margin: 0;
}

.info-popup {
  margin-left: 0.7em;
  cursor: pointer;
}

.calcul .case {
  border-radius: 0 0 0.25rem 0.25rem;
  width: 100%;
}

.description-case {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: 9rem !important;
  margin-top: 4rem;
  text-align: center;
  font-size: 0.9em;
}

.calcul {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin-top: 2rem;
}

datalist {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 15rem;
  height: 8rem;
  top: 4.5rem;
  position: absolute;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  z-index: 1 !important;
  pointer-events: none;
}

datalist option {
  background: white;
  min-height: 13px;
  height: 10px;
  z-index: 0 !important;
}

.div-input {
  position: relative;
}

datalist option:first-of-type {
  visibility: hidden;
}

datalist option:last-of-type {
  visibility: hidden;
}

.sub-column-name {
  font-size: 0.7em;
  font-family: 'Nexa-Light';
}
/** for chrome **/
input[type='range'] {
  margin: 0;
  margin-top: 4.5rem !important;
  width: 15rem;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  position: relative;
  box-sizing: border-box;
  height: 8rem;
}

input[type='range']::-webkit-slider-thumb {
  color: rgb(24, 160, 150);
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  z-index: 5 !important;
  height: 8rem;
  background: #127e77;
  cursor: pointer;
}

/** FF **/
input[type='range']::-moz-range-thumb {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  z-index: 5 !important;
  height: 8rem;
  background: #127e77;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 0;
}

input[type='range']::-moz-range-progress {
  background-color: #18a096;
}
input[type='range']::-moz-range-track {
  background-color: #888888;
}

/** IE **/
input[type='range']::-ms-fill-lower {
  background-color: #18a096;
}
input[type='range']::-ms-fill-upper {
  background-color: #888888;
}

.info-column-popup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.info-column-popup img {
  -webkit-align-self: center;
          align-self: center;
}

.img-modal {
  width: 100% !important;
}

.download-position {
  -webkit-align-self: center;
          align-self: center;
  cursor: pointer;
  outline: none;
  font-family: 'Nexa-Light';
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 20em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
  font-size: 1.1em;
  margin-top: 1rem;
}

.download-position:hover {
  background-color: #10746d;
}

.valeur-column {
  position: absolute;
  z-index: 1;
  left: 3.5rem;
  top: 1.7em;
  color: white;
  width: 8rem;
  font-size: 2.5em;
  text-align: center;
  font-family: 'Nexa-Bold';
  pointer-events: none;
}

.head-assistant .position-actuel {
  width: 35%;
}

.head-assistant .position-nouveau {
  width: 35%;
}

.head-assistant .position-nouveau .case {
  box-shadow: 2px 2px 5px 0px #252f3f;
}

.head-assistant .case {
  border-radius: 0 0 0.25rem 0.25rem;
  width: auto;
}

.head-assistant .column-position {
  margin: 1rem 0 0 0;
  border-radius: 0.25rem 0.25rem 0 0;
  width: auto;
}

.head-assistant .description-header {
  color: black;
  margin: 5rem;
}

.div-calcul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
}

.buttons-calcul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin: 1rem 0 0 0;
}

#valider {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 9rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem 0rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #f8b332;
  text-decoration: none;
  font-family: 'Nexa';
  font-size: 1.1em;
  outline: none;
  cursor: pointer;
}

#valider:active {
  background-color: #cc9329;
}

.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  opacity: 0.2 !important;
}

.calcul {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

#calculer {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 9rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem 4rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
  text-decoration: none;
  font-family: 'Nexa';
  font-size: 1.1em;
  outline: none;
  cursor: pointer;
}

#calculer:active {
  background-color: #127e77;
}

#simuler {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 9rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem 0rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
  text-decoration: none;
  font-family: 'Nexa';
  font-size: 1.1em;
  outline: none;
  cursor: pointer;
}

#simuler:active {
  background-color: #127e77;
}

.head-assistant {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.head-assistant h2 {
  text-align: center;
}

.select-input {
  width: 10rem;
}

.simulateur-cout-global {
  font-size: 1.1em;
}

.simulateur-cout-global label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

#temps-partiel {
  -webkit-align-items: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-flex;
  display: flex;
  padding: 2px 8px;
  width: 5rem;
  font-family: 'Nexa-Light';
  font-size: 1.03em;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
}

#temps-partiel:focus {
  border: 1px solid #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}

.show-position {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
}

.block-head {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.block-head h2 {
  font-size: 1.1em;
}

.show-position .resultat-glob {
  width: 49% !important;
}

.resultat-glob {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.infos-simulation {
  margin-top: 4em;
  font-size: 0.85em;
}

.top-bar {
  height: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  background-color: #888888;
  padding: 0.75rem 0;
}

.top-bar h2 {
  margin: 0;
  padding: 0.75rem;
  width: 30%;
  text-align: left;
}

.top-bar div {
  padding: 0.75rem;
  padding-left: 10rem;
  height: 100%;
  width: 70%;
  text-align: left;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.5em;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 5% 100%);
          clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 5% 100%);
  background-color: #4b4b4d;
}

.top-bar p {
  margin: 0;
}

.top-bar-emploi {
  margin-left: 10px !important;
}

#emploi-input {
  padding: 0.2em;
  font-family: 'Nexa-Light';
  font-size: 1.1em;
  width: 16em;
}

.ok-pour-moi {
  box-shadow: 0 0 #0000,
    0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 13rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.5rem 2rem;
  border: solid 1px transparent;
  border-radius: 0.25rem;
  color: white;
  background-color: #18a096;
  text-decoration: none;
  font-family: 'Nexa';
  font-size: 1.1em;
  outline: none;
  cursor: pointer;
}

.ok-pour-moi:active {
  background-color: #127e77;
}

.bar-button {
  margin-top: 2em;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  place-content: center;
}

.alert-popup {
  font-size: 1.2em;
}

.alert-popup h2 {
  color: red;
}

.alert-popup button {
  width: auto !important;
}

.alert-popup .buttons-popup {
  -webkit-justify-content: center;
          justify-content: center;
}

.date-pick {
  padding: 0.5em;
  border-radius: 5px;
  font-family: 'Nexa-Light';
}

.window-backoffice {
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  height: 90vh;
}

.MuiAppBar-colorPrimary {
  background-color: #4b4b4d !important;
}

.input-code {
  font-family: 'Nexa-Light' !important;
  margin-bottom: 1em !important;
}

.input-code fieldset {
  border-color: #000000 !important;
}

.input-code label:focus {
  color: black;
}

.choix-date {
  background-color: white;
  padding: 1em;
  width: auto;
  width: 20em;
  display: -webkit-flex;
  display: flex;
  z-index: 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.input-code * {
  font-family: 'Nexa-bold' !important;
}

.date-pick * {
  font-family: 'Nexa-bold' !important;
}

.choix-date p {
  font-family: 'Nexa-Light' !important;
}

.field-date {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

.field-date p {
  margin-right: 1em;
}

.MuiAppBar-positionFixed {
  position: relative !important;
}

.MuiTab-textColorPrimary * {
  font-family: 'Nexa-Light';
}

.MuiTab-textColorPrimary.Mui-selected * {
  color: black !important;
  font-family: 'Nexa-Bold' !important;
}

.MuiTab-textColorPrimary {
  width: 50% !important;
  max-width: 100% !important;
  color: rgba(255, 255, 255, 0.54);
}

.makeStyles-root-5 {
  margin-bottom: 3em;
  border-color: #18a096 !important;
}

.choix-date #export-csv-entreprise {
  margin-top: 3em;
  padding: 0.5em 1em;
  width: 18em;
  font-family: none !important;
  font-family: 'Nexa-bold' !important;
  font-size: 0.8em;
  background-color: #3f51b5 !important;
  color: white !important;
}

.choix-date #export-csv-salaries {
  margin-top: 0.9em;
  padding: 0.5em 1em;
  width: 18em;
  font-family: none !important;
  font-family: 'Nexa-bold' !important;
  font-size: 0.8em;
  background-color: #18a096 !important;
  color: white !important;
}

.choix-date #export-csv-calculs {
  margin-top: 0.9em;
  padding: 0.5em 1em;
  width: 18em;
  font-family: none !important;
  font-family: 'Nexa-bold' !important;
  font-size: 0.8em;
  background-color: #4b4b4d !important;
  color: white !important;
}

.top-bar-backoffice {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
  font-family: 'Nexa-Light' !important;
}

.top-bar-backoffice * {
  font-family: 'Nexa-Light' !important;
}

.disconnect-top-bar * {
  font-family: 'Nexa-bold' !important;
}

.jss8 {
  background-color: #000000 !important;
}

.MuiTabs-indicator {
  background-color: #000000 !important;
}
#myChart {
  width: 65vw !important;
  height: auto !important;
  margin-top: 3em;
  max-height: 80vh;
}

.PrivateTabIndicator-colorSecondary-8 {
  background-color: black !important;
}

.MuiTabs-indicator {
  left: 0 !important;
  width: 100% !important;
  right: 0 !important;
}

.makeStyles-formControl-7 {
  width: 70% !important;
}

.choix-date .MuiInputLabel-outlined {
  background-color: white !important;
}

.choix-date .MuiFormControl-root {
  width: 70% !important;
}

.login-page {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  place-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
}

.login-page input,
.login-page label {
  background-color: white;
  font-family: 'Nexa-Light';
}

.login-page form {
  box-shadow: none;
}

.input-login fieldset {
  border-color: #18a096 !important;
}

.input-login label:focus {
  color: black;
}

.MuiFormLabel-root.Mui-focused {
  color: black !important;
}

.login-page form > div {
  width: 100%;
  margin-top: 1em;
}

.div-formulaire {
  width: 20em;
  background-color: white;
  padding: 2em;
  border-radius: 8px;
  box-shadow: 0 19px 38px rgba(83, 83, 83, 0.3),
    0 15px 12px rgba(83, 83, 83, 0.22);
}

.login-page form {
  background-color: white;
}

.login-page h1 {
  margin-top: 0;
  text-align: center;
  font-family: 'Nexa-bold';
}

.login-page h4 {
  text-align: center;
  margin-bottom: 2em;
  font-family: 'Nexa-Light';
}

.login-page button {
  margin-top: 2em;
  font-family: 'Nexa-bold';
  background-color: #18a096;
  padding: 0.7em 2em;
  text-transform: capitalize;
}

.login-page button:hover {
  background-color: #14867f;
}

.login-page form .login-page form > div:nth-child(1) {
  margin-top: 0;
}

.login-page button span {
  font-size: 1.2em !important;
}

.login-page form label:nth-child(odd) {
  background: none;
}

.MuiOutlinedInput-inputMarginDense {
  font-family: 'Nexa-Light' !important;
}
.login-page iframe {
  width: 100%;
  height: auto;
}

